import React, { useEffect, forwardRef } from "react"
import * as styles from "./popupModal.module.css"

const windowStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "100%",
	zIndex: "10",
	position: "absolute",
	backgroundColor: "rgba(0,0,0,0.25)"
}

//panna: #f8efcc. arancio: #f0b16e. verde scuro: #2a8266. rosso: #ba4b0b

const BackgroundOverlay = forwardRef(({children, closeCallBack, isOpen}, ref) => {
	const modalRef = React.useRef()

	const handleKeyUp = (ev) => {
		// if ESC key is pressed, close the modal
		return ev.keyCode === 27 ? closeCallBack(true) : null
	}

	const handleOnClick = (ev) => {
		return ev.target.id === "bgOverlay" ? closeCallBack(false) : null
	}

	useEffect( () => {
		if( isOpen ) modalRef.current.focus()
	}, [isOpen])

	return (
		<div id="bgOverlay" role="tab" tabIndex="-1" ref={modalRef} onClick={handleOnClick} onKeyUp={handleKeyUp} style={windowStyle}>
			{children}
		</div>
	)
})

const PopUpModal = ({title, content, isOpen, closeFn}) => {

	return isOpen ? <BackgroundOverlay closeCallBack={closeFn} isOpen={isOpen}>
						<div className={styles.modal_content}>
							<h1 style={{color:"#ffa500"}}>{title}</h1>
							{content}
						</div>
					</BackgroundOverlay> : null

}


export default PopUpModal
