import React, { useState, useCallback, useRef, forwardRef } from "react"


const draggableStyle = {
	display: "flex",
	justifyContent: "center",
	touchAction: "none",
	userSelect: "none",
	height: "100%",
	backgroundColor: "transparent",
 	border: "none",
	padding: "0"
}

// const getDistance = (x1, y1, x2, y2) => {
// 	return Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2))
// }


const Draggable = forwardRef(({ children, id, tabID, name, onDragEnter, onTouchEnd, onDragEnd, onCardSelect, customStyle }, ref) => {
	const [drag, setDrag] = useState(false)
	const clientX = useRef()
	const clientY = useRef()
	const offsX = useRef(0)
	const offsY = useRef(0)


	const handleKeyUp = (e) => {
		// if spacebar key, the card is selected
		return e.keyCode === 32 ? onCardSelect(id) : null
	}

	const handleDragStart = useCallback (
		(ev) => {
			// Add the target element's id to the data transfer object
    		ev.dataTransfer.setData("text/plain", ev.currentTarget.id)
			ev.dataTransfer.effectAllowed = "move"
			setDrag(true)
		}, []
	)

	const handleDragEnd = useCallback (
		(ev) => {
			setDrag(false)
			if(onDragEnd) onDragEnd(ev)
		}, [onDragEnd]
	)

	const handleTouchStart = useCallback (
		(ev) => {
			ev.stopPropagation()
			const touchPoint = ev.touches[0]

			// Cache the client X/Y coordinates
			clientX.current = touchPoint.clientX;
			clientY.current = touchPoint.clientY;
			setDrag(true)
		}, []
	)

	const handleTouchMove = useCallback (
		(ev) => {
			let x, y = 0
			let target = ev.currentTarget
			const changed = ev.changedTouches[0]

			const deltaX = changed.clientX - clientX.current
			const deltaY = changed.clientY - clientY.current

			x = offsX.current + deltaX
			y =  offsY.current + deltaY

			// translate the element
			target.style.transform = `translate(${x}px, ${y}px)`
		}, []
	)

	const handleTouchEnd = useCallback (
		(ev) => {
			const changed = ev.changedTouches[0]
			let target = ev.currentTarget
			//offsX.current += changed.clientX - clientX.current
			//offsY.current += changed.clientY - clientY.current
			const deltaX = changed.clientX - clientX.current
			const deltaY = changed.clientY - clientY.current

			const deltaCoords = {
				top: target.offsetTop + deltaY,
				left: target.offsetLeft + deltaX,
				bottom: (target.offsetTop + deltaY) + target.offsetHeight,
				right: (target.offsetLeft + deltaX) + target.offsetWidth,
			}

			target.style.transform = `translate(0px, 0px)`
			setDrag(false)
			onTouchEnd(ev, deltaCoords)
		}, [onTouchEnd]
	)

	return (
		<button role="tab" id={id} tabIndex={tabID ? tabID : -1} aria-describedby="operation"
			draggable="true"
			ref={drag ? ref : null}
			onDragStart={handleDragStart}
			onDragEnter={onDragEnter}
			onDragEnd={handleDragEnd}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
			onKeyUp={handleKeyUp}
			style={{...draggableStyle, ...customStyle}}
			>
			{children}
		</button>
	)
})

export default Draggable
