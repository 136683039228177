// extracted by mini-css-extract-plugin
export var card = "index-module--card--X2qy1";
export var card_content_list = "index-module--card_content_list--cMpHa";
export var cell_book = "index-module--cell_book--PZMgh";
export var cell_d20 = "index-module--cell_d20--Cgf6Z";
export var cell_drop = "index-module--cell_drop--88Eup";
export var cell_glasses = "index-module--cell_glasses--sCSWh";
export var cell_icons = "index-module--cell_icons--POzrN";
export var cell_joycon = "index-module--cell_joycon--SHMe4";
export var cell_juice = "index-module--cell_juice--B8C14";
export var cell_list = "index-module--cell_list--DOm0Y";
export var contact_link = "index-module--contact_link--BiqsV";
export var img_book = "index-module--img_book--ExlLs";
export var img_d20 = "index-module--img_d20--qdlpr";
export var img_github = "index-module--img_github--fIQRo";
export var img_github_wrap = "index-module--img_github_wrap--N9BuW";
export var img_mail = "index-module--img_mail--tB0p6";
export var img_mail_wrap = "index-module--img_mail_wrap--hLBAA";
export var link_spacing = "index-module--link_spacing--YDxmD";
export var main_container = "index-module--main_container--zSsmY";
export var main_container_grid = "index-module--main_container_grid--9ngVj";