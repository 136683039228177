import React from "react"


const assistive_text = {
	position: "absolute",
	margin: "-1px",
	border: 0,
	padding: 0,
	width: "1px",
	height: "1px",
	overflow: "hidden",
	clip: "rect(0 0 0 0)"
}

const Announcer = ({liveContent}) => {
	return <span id="operation" aria-live="polite" style={assistive_text}> {liveContent} </span>
}

export default Announcer
