import React, { useCallback, useState } from "react"
import Draggable from "./draggable"
import * as styles from "./dropzone.module.css"
import PopUpModal from "./popupModal"
import {default as LiveTextAnnouncement} from "./announcer"


const dropZoneStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	border: "5px solid white",
	color: "white",
	textAlign: "center"
}

const listStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: 0,
	marginBottom: "0.1em"
}

const dragContainer = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	listStyleType: "none",
	height: "100%",
	padding: 0,
}


const elementsOverlap = (origin, target) => {
	const {top, left, bottom, right} = origin
	const tRect = target.getBoundingClientRect();

	return !(
		top > tRect.bottom ||
		right < tRect.left ||
		bottom < tRect.top ||
		left > tRect.right
	);
}

const DropZone = ({children, list, listCName, dropCName}) => {
	const dragRef = React.useRef()
	const dropRef = React.useRef()
	const [dragList, setDragList] = useState(list)
	const [dropped, setDropped] = useState(null)
	const [left, setLeft] = useState(false)
	const [showModal, setShowModal] = useState(false)


	// TODO: Should always keep the card in the drop slot and let keyboard user
	// to interact with the dropped card to clear it
	const handleCloseModal = ( byKeyboard ) => {
		setShowModal(false)
		if( byKeyboard ) {
			setDropped(null)
			setDragList(list)
		}
	}

	const updateList = useCallback (
		(id, curList) => {
			if( dropped && dropped.id === id ) return
			const curDrag = dragList.find((item) => item.id === id)

			setDropped({
				id: id,
				img: curDrag.img,
				title: curDrag.title,
				content: curDrag.content
			})

			setDragList(curList.filter((item) => item.id !== id))
			setShowModal(true)
		}, [dragList, dropped]
	)

	const handleDragEnter = useCallback (
		(ev) => {
			setLeft(false)
		}, []
	)

	const handleDragOver = useCallback (
		(ev) => {
			ev.preventDefault()
			ev.dataTransfer.dropEffect = "move"
		}, []
	)

	const handleDragLeave = useCallback (
		(ev) => {
			setLeft(true)
		}, []
	)

	const handleDrop = useCallback (
		(ev) => {
			ev.preventDefault()

			updateList(ev.dataTransfer.getData("text/plain"), list)
		}, [updateList, list]
	)

	const handleDragEnd = useCallback (
		(ev) => {
			if( left ) {
				setDropped(null)
				setDragList(list)
			}
		}, [left, list]
	)

	const handleTouchEnd = (ev, coords) => {
		// On touch end, if a new item is dropped in the area, update the dropzone and the list of cards.
		if( elementsOverlap(coords, dropRef.current) ) {
			updateList(ev.currentTarget.id, list)
		} else {
			// otherwise delete the card in the dropzone if the card that was touched was the one
			// in the dropzone and not one in the card list.
			if( dragRef.current.id === dropRef.current.childNodes[0].id ) {
				setDropped(null)
				setDragList(list)
			}
		}
	}

	const update_modal = useCallback (
		(id) => {
			updateList(id, list)
		}, [updateList, list]
	)

	return (
		<>
		<LiveTextAnnouncement liveContent={dropped ? dropped.content : "Press tab to select a card, press space bar to read its content"} />

		<PopUpModal title={dropped ? dropped.title : ""} content={dropped ? dropped.content : ""} isOpen={showModal} closeFn={handleCloseModal}/>
		<div style={listStyle} className={listCName}>
			<ul style={dragContainer}>
				{ dragList.map( (el, index) => {
					return 	<li key={el.id} style={{height:"100%"}}>
								<Draggable
									id={el.id}
									// Make cards in the list not interactible if the modal is open
									// TODO: Should make all of this with Redux
									tabID={showModal ? -1 : index+1}
									name={el.name}
									ref={dragRef}
									onCardSelect={update_modal}
									onTouchEnd={handleTouchEnd}
									customStyle={{position: "relative", zIndex: "2"}}
								>
									{el.img}
								</Draggable>
							</li>
				}) }
			</ul>
		</div>

		<div role="tab" id="target" ref={dropRef}
			className={dropCName}
			onDrop={handleDrop}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			style={dropZoneStyle}
		>
			{
				dropped ? <Draggable id={dropped.id} ref={dragRef}
					 				 onDragEnter={handleDragEnter}
									 onDragEnd={handleDragEnd}
									 onTouchEnd={handleTouchEnd}
						  >
						  	{dropped.img}
						  </Draggable> : children
			}
		</div>
		</>
	)
}

export default DropZone
