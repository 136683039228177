import React from "react"
import * as styles from "./index.module.css"
import * as reset from "./normalize.css"
import DropZone from "../components/dropzone"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"


const highlight = {
	color: "#ba4b0b"
}

const linksStyle = {
	color: "#ffa500"
}

const cardsList = [
	{
		id: "drag-0",
		name: "About",
		img: <StaticImage key="drag-0-key" src="../images/About.png" alt="About Card" placeholder="blurred" className={styles.card} objectFit="contain" />,
		title: "ABOUT",
		content: <article>
					Hi I'm <span style={highlight}>Giuseppe</span>!<br/>
		 			I'm a self taught <span style={highlight}>Frontend Developer</span> who builds <span style={highlight}>custom </span>
					blogs, landing pages, portfolios, galleries, websites (you name it!), with great attention on
					<span style={highlight}> performance</span>,
					<span style={highlight}> SEO </span>
					and
					<span style={highlight}> accessibility</span>.<br/><br/>

					The technologies that I focus on are
					<span style={highlight}> HTML</span>,
					<span style={highlight}> CSS</span>,
					<span style={highlight}> Javascript</span>,
					<span style={highlight}> React</span> and
					in a lesser way many others that I won't bother you with here.<br/>
					You may find more information in the <span style={highlight}> WORKS</span> card. <br/><br/>
					I'm sure you can't wait to contact me, so please go ahead and get all the information that you need in the <span style={highlight}>INFO</span> card!
				 </article>
	},
	{
		id: "drag-1",
		name: "Works",
		img: <StaticImage key="drag-1-key" src="../images/Works.png" alt="Works Card" placeholder="blurred" className={styles.card} objectFit="contain" />,
		title: "WORKS",
		content: <article>
					In this section I'll share a few of my works along with the <span style={highlight}>technologies</span> that I used to build them.<br/><br/>
					<ol>
						<li>
							<span style={highlight}>Samantha Santoni Portfolio.</span><br/>
							<a href="https://www.samanthasantonidesign.com" target="_blank" rel="noreferrer noopener" style={linksStyle}>This</a> is a simple portolio site, with a gallery of all the amazing works done
							by the graphic designer who also did the pixel art that you can see in this site.<br/><br/>

							This portfolio has also a CMS that will allow the owner to add new works along the way.<br/>
							<span style={highlight}>Technologies:</span><br/>
							<ul className={styles.card_content_list}>
								<li>Reactjs.</li>
								<li>Netlify CMS.</li>
								<li>Gatsbyjs.</li>
								<li>GraphQL.</li>
								<li>Redux.</li>
							</ul>
						</li><br/>

						<li>
							<span style={highlight}>Fumetteria Centro del Mondo.</span><br/>
							<a href="https://fumetteriacentrodelmondo.com" target="_blank" rel="noreferrer noopener" style={linksStyle}>This</a> is a landing page site commissioned by Fumetteria Centro del Mondo a comics book shop.<br/>
							<span style={highlight}>Technologies:</span><br/>
							<ul className={styles.card_content_list}>
								<li>Reactjs.</li>
								<li>Gatsbyjs.</li>
								<li>GraphQL.</li>
							</ul>
						</li><br/>

						<li>
							<span style={highlight}>Reddit Minimal.</span><br/>
							<a href="https://github.com/JuseGit/reddit_minimal" target="_blank" rel="noreferrer noopener" style={linksStyle}>This</a> is a personal project that mimics Reddit behavior.<br/>
							With the help of <a href="https://github.com/reddit-archive/reddit/wiki/JSON" target="_blank" rel="noreferrer noppener" style={linksStyle}>Reddit JSON API</a>,
							it is possible to gather a few subreddits,
							see posts content and comments<br/>
							<span style={highlight}>Technologies:</span><br/>
							<ul className={styles.card_content_list}>
								<li>Reactjs.</li>
								<li>Gatsbyjs.</li>
								<li>GraphQL.</li>
								<li>Reddit JSON API.</li>
								<li>Redux.</li>
							</ul>
						</li>
					</ol>
				 </article>
	},
	{
		id: "drag-2",
		name: "Info",
		img: <StaticImage key="drag-2-key" src="../images/Info.png" alt="Info Card" placeholder="blurred" className={styles.card} objectFit="contain" />,
		title: "INFO",
		content: <article>
					<span style={highlight}>Contacts.</span><br/>
					If you have a new project in mind for your activity or want to give feedback on this website let's have a talk!
					Here's my <span style={highlight}>links</span>:<br/>
					<ul className={styles.card_content_list}>
						<li><a href="mailto:jusedev@gmail.com" alt="Juse Github link" target="_blank" rel="noreferrer noopener" style={linksStyle}>Mail</a></li>
						<li><a href="https://github.com/JuseGit" alt="Juse Email link" target="_blank" rel="noreferrer noopener" style={linksStyle}>GitHub</a></li>
						<li><a href="https://www.linkedin.com/in/giuseppe-bertolino-6b10b6252/" alt="Juse LinkedIn link" target="_blank" rel="noreferrer noopener" style={linksStyle}>LinkedIn</a></li>
					</ul>
				 </article>
	}
]

// markup
const IndexPage = () => {
	return (
		<main className={styles.main_container_grid}>
			<SEO title="Home"/>

			<StaticImage src="../images/ace.png" alt="Fruit Juice ACE" placeholder="blurred" draggable="false" className={styles.cell_juice} objectFit="contain"/>

			<StaticImage src="../images/book_eye.png" alt="Book Eye" placeholder="blurred" draggable="false" className={styles.cell_book} objectPosition="right top" objectFit="contain"/>

			<StaticImage src="../images/D20.png" alt="D20" draggable="false" placeholder="blurred" className={styles.cell_d20} objectFit="contain"/>

			<div className={styles.cell_icons}>
				<a href="https://github.com/JuseGit" alt="Juse Github link" target="_blank" rel="noreferrer noopener" className={styles.contact_link + " " + styles.link_spacing}>
					<StaticImage src="../images/icon_github.png" alt="Github icon" placeholder="blurred" draggable="false" className={styles.img_github_wrap} imgClassName={styles.img_github} objectFit="contain"/>
				</a>

				<a href="mailto:jusedev@gmail.com" alt="Juse Email link" target="_blank" rel="noreferrer noopener" className={styles.contact_link}>
					<StaticImage src="../images/icon_mail.png" alt="Mail icon" placeholder="blurred" draggable="false" className={styles.img_mail_wrap} imgClassName={styles.img_mail} objectFit="contain"/>
				</a>
			</div>

			<StaticImage src="../images/joycon.png" alt="Joycon" draggable="false" placeholder="blurred" className={styles.cell_joycon} objectPosition="bottom" objectFit="contain"/>

			<StaticImage src="../images/glasses.png" alt="Glasses" draggable="false" placeholder="blurred" className={styles.cell_glasses} objectFit="contain"/>

			<DropZone list={cardsList} listCName={styles.cell_list} dropCName={styles.cell_drop} >
				Drop Here!
			</DropZone>
		</main>
	)
}

export default IndexPage
